export const routes = [
    {
        path: '/sale_items',
        name: 'sale_items.browse',
        component: () => import(/* webpackChunkName: "SaleItemsBrowse" */ '@/views/app/SaleItemsBrowse.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/sale_items/create',
        name: 'sale_items.create',
        component: () => import(/* webpackChunkName: "SaleItemsActions" */ '@/views/app/SaleItemsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/sale_items/:id/edit',
        name: 'sale_items.edit',
        component: () => import(/* webpackChunkName: "SaleItemsActions" */ '@/views/app/SaleItemsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup, asi" }
    },
    {
        path: '/sale_items/:id/delete',
        name: 'sale_items.delete',
        component: () => import(/* webpackChunkName: "SaleItemsActions" */ '@/views/app/SaleItemsActions.vue'),
        meta: { guard: 'verified', roles: "adm, sup" }
    },
]